.btn-status {
  padding: 5px 7px;
  border: none;
  border-radius: 10px;
}

.APPROVED {
  background-color: green;
  color: white;
}

.DECLINED {
  background-color: red;
  color: white;
}

.PENDING {
  background-color: lightblue;
  color: black;
}

.rbc-calendar {
  height: 100% !important;
}
