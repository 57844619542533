.WrapperHoverSidebar {
    .test{
    margin-right:1.5rem !important;
    }
    /* .menu-otherthan-home{
      margin-right:2rem !important;
    } */
  }
  .WrapperHoverSidebar:hover {
    .test{
    margin-right: 1rem !important;
    }
    /* .menu-otherthan-home{
      margin-right:1rem !important;
    } */
  }
  .HomeIcon {
    margin-right: 0rem !important;
  }