/* body {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
} */
/* input[type="text"],
input[type="date"],
input[type="email"] {
  border-radius: 0 !important;
  background: transparent !important;
}
textarea {
  padding-left: 5px !important;
  border-radius: 0 !important;
  background: transparent !important;
  resize: none !important;
} */
/* table {
  width: 100%;
} */
/* p {
  text-align: justify !important;
} */
/* .form {
  width: 10in;
  margin: 30px 0;
} */
/* .heading {
  font-weight: 700;
  font-size: larger;
} */
.form-sr-no {
    display: block;
    margin-right: 20px;
  }
  /* .font-size-larger {
    font-size: larger;
  } */
  /* .font-size-medium {
    font-size: medium;
  } */
  .reg-cells {
    padding-left: 100px;
    margin-top: -10px;
  }
  .reg-no {
    display: inline-block;
    width: 45px;
  }
  .reg-cell {
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .bt-1 {
    border-top: 1px solid #000;
  }
  .border-all {
    border: 1px solid #000;
  }
  .border-none {
    border: none;
  }
  .company-name {
    display: inline-block;
    width: 100%;
  }
  .challan-no {
    width: 200px;
    margin-top: -10px;
  }
  .form-19-challan-no {
    width: 200px;
    margin-left: 25px;
  }
  .amount {
    width: 200px;
    margin-top: -10px;
  }
  .form-19-amount {
    width: 200px;
    height: 30px !important;
    margin-left: 50.5px;
  }
  .td-h,
  .tr-h {
    height: 20px !important;
  }
  .vertical-align-top {
    vertical-align: top !important;
  }
  .vertical-align-middle {
    vertical-align: middle !important;
  }
  .strong {
    font-weight: bold;
  }
  .declaration {
    padding-left: 35px;
  }
  .declaration-ol {
    counter-reset: list;
    padding-left: 65px;
  }
  
  .declaration-ol > li {
    list-style: none;
    position: relative;
  }
  
  .declaration-ol > li:before {
    content: "(" counter(list, lower-roman) ")   ";
    counter-increment: list;
    left: -50px;
    padding-right: 10px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  .authorized-officer-designation {
    width: 100%;
    height: 90px;
  }
  .signatures {
    width: 100%;
    height: 40px;
    margin-top: -10px;
  }
  .date-day,
  .date-month {
    width: 54px;
    height: 43px;
  }
  .date-year {
    width: 54px;
    height: 40px;
  }
  
  .vertical-rl {
    writing-mode: vertical-rl;
    rotate: 180deg;
    text-align: center;
  }
  .vertical-rl-text-start {
    writing-mode: vertical-rl;
    rotate: 180deg;
  }
  
  .border-bottom-3 {
    border-bottom: 3px solid #000;
  }
  
  .ipcori-ol {
    counter-reset: list;
    padding-left: 90px;
  }
  
  .ipcori-ol > li {
    list-style: none;
    position: relative;
  }
  
  .ipcori-ol > li:before {
    content: "(" counter(list, lower-alpha) ")   ";
    counter-increment: list;
    font-weight: bold;
    left: -50px;
    top: -5px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  
  .ipcori-sub-ol {
    counter-reset: list;
    padding-left: 40px;
  }
  
  .ipcori-sub-ol > li {
    list-style: none;
    position: relative;
  }
  
  .ipcori-sub-ol > li:before {
    content: "(" counter(list, lower-roman) ")   ";
    counter-increment: list;
    left: -50px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  
  .fh-ol {
    counter-reset: list;
    padding-left: 90px;
  }
  
  .fh-ol > li {
    list-style: none;
    position: relative;
  }
  
  .fh-ol > li:before {
    content: "(" counter(list, lower-alpha) ")   ";
    counter-increment: list;
    font-weight: bold;
    left: -50px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  
  .fh-sub-ol {
    padding-left: 0px;
  }
  
  .fh-sub-ol > li {
    list-style: none;
    position: relative;
  }
  
  .fh-sub-ol > li:before {
    left: -50px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  
  .inline-input {
    display: inline-block;
    width: 100px;
    margin-top: -5px;
  }
  .inline-input-h {
    height: 12px !important;
  }
  .em-instruction {
    margin-left: -50px;
    color: #727272;
  }
  
  .center-ol {
    counter-reset: list;
  }
  
  .center-ol > li {
    list-style: none;
    position: relative;
  }
  
  .center-ol > li:before {
    content: counter(list, lower-roman) ".";
    counter-increment: list;
    font-weight: bold;
    left: -50px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  
  .cri-ol {
    counter-reset: list;
    padding-left: 90px;
  }
  
  .cri-ol > li {
    list-style: none;
    position: relative;
  }
  
  .cri-ol > li:before {
    content: "(" counter(list, lower-alpha) ")   ";
    counter-increment: list;
    left: -50px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  .acceptance-mode-ol {
    counter-reset: list;
    padding-left: 50px;
  }
  
  .acceptance-mode-ol > li {
    list-style: none;
    position: relative;
  }
  
  .acceptance-mode-ol > li:before {
    content: "(" counter(list, lower-roman) ")   ";
    counter-increment: list;
    left: -50px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  .major-risks-ol {
    counter-reset: list;
    padding-left: 50px;
  }
  
  .major-risks-ol > li {
    list-style: none;
    position: relative;
  }
  
  .major-risks-ol > li:before {
    content: "(" counter(list, lower-roman) ")   ";
    counter-increment: list;
    left: -50px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  .enclosure-ol {
    counter-reset: list;
    padding-left: 50px;
  }
  
  .enclosure-ol > li {
    list-style: none;
    position: relative;
  }
  
  .enclosure-ol > li:before {
    content: counter(list, numbers) ".";
    counter-increment: list;
    left: -40px;
    padding-right: 20px;
    position: absolute;
    text-align: right;
    width: 40px;
  }
  .text-align-justify {
    text-align: justify !important;
  }
  .inline-input-50 {
    display: inline-block;
    width: 50px;
  }
  .inline-input-100 {
    display: inline-block;
    width: 100px;
  }
  .inline-input-150 {
    display: inline-block;
    width: 150px;
  }
  .inline-input-190 {
    display: inline-block;
    width: 190px;
  }
  .inline-input-200 {
    display: inline-block;
    width: 200px;
  }
  .inline-input-240 {
    display: inline-block;
    width: 240px;
  }
  .inline-input-282 {
    display: inline-block;
    width: 282px;
  }
  .inline-input-300 {
    display: inline-block;
    width: 300px;
  }
  .inline-input-317 {
    display: inline-block;
    width: 317px;
  }
  .inline-input-353 {
    display: inline-block;
    width: 353px;
  }
  .inline-input-500 {
    display: inline-block;
    width: 500px;
  }
  .inline-input-600 {
    display: inline-block;
    width: 600px;
  }
  .word-spacing-10 {
    word-spacing: 9.5px;
  }
  .word-spacing-12 {
    word-spacing: 12px;
  }
  .word-spacing-15 {
    word-spacing: 16px;
  }
  .word-spacing-20 {
    word-spacing: 20px;
  }
  .word-spacing-25 {
    word-spacing: 25px;
  }
  .word-spacing-30 {
    word-spacing: 30px;
  }
  .word-spacing-35 {
    word-spacing: 35px;
  }
  .word-spacing-40 {
    word-spacing: 40px;
  }
  .word-spacing-45 {
    word-spacing: 45px;
  }
  .word-spacing-50 {
    word-spacing: 50px;
  }
  .word-spacing-55 {
    word-spacing: 55px;
  }
  .word-spacing-60 {
    word-spacing: 60px;
  }
  .word-spacing-65 {
    word-spacing: 65px;
  }
  .word-spacing-68 {
    word-spacing: 68px;
  }
  .form-20-date-cell {
    padding: 1px !important;
    width: 30px;
    height: 30px !important;
  }
  .border-collapse-separate {
    border-collapse: separate;
  }
  .box {
    margin-right: 35px;
  }
  .border-right {
    border-right: 1px solid #000;
  }
  .input-h-20 {
    height: 25px !important;
  }
  .form-21-particulars {
    padding-left: 30px;
  }
  .particulars-col-1 {
    width: 80%;
  }
  .particulars-col-2 {
    width: 20%;
  }
  .particulars-line {
    width: 12px;
    border-top: 2px solid black;
    margin-left: 5px;
    margin-top: 14px;
  }
  .notices-part {
    display: block;
    width: 15%;
    border-right: 1px solid #000;
  }
  .notices-part-heading {
    display: block;
    width: 85%;
    margin-left: 10px;
  }
  .notices-checkbox {
    height: 20px !important;
    cursor: pointer;
  }
  
  .form-no {
    /* font-size: medium; */
    font-weight: 900;
    border-bottom: 2px solid #000;
  }
  /* .font-large {
    font-size: large;
  } */
  
  /* .app3-appendix-a-ol {
    counter-reset: firststlevel;
  }
  
  .app3-appendix-a-ol > li {
    list-style: none;
    position: relative;
  }
  
  .app3-appendix-a-ol > li:before {
    content: counter(firststlevel, numbers);
    counter-increment: firststlevel;
    font-size: 20px;
    font-weight: bold;
    left: -25px;
    top: -1px;
    position: absolute;
  }
  .app3-appendix-a-sub1-ol {
    counter-reset: secondlevel;
  }
  
  .app3-appendix-a-sub1-ol > li {
    list-style: none;
    position: relative;
  }
  
  .app3-appendix-a-sub1-ol > li:before {
    content: counter(firststlevel, numbers) "." counter(secondlevel);
    counter-increment: secondlevel;
    font-size: 15px;
    left: -63px;
    position: absolute;
  }
  .app3-appendix-a-sub2-ol {
    counter-reset: thirdlevel;
  }
  
  .app3-appendix-a-sub2-ol > li {
    list-style: none;
    position: relative;
  }
  
  .app3-appendix-a-sub2-ol > li:before {
    content: counter(firststlevel, numbers) "." counter(secondlevel) "."
      counter(thirdlevel);
    counter-increment: thirdlevel;
    font-size: 15px;
    left: -50px;
    position: absolute;
  } */
  
  .aaa3-list1-no {
    display: inline-block;
    /* font-size: large; */
    font-weight: bold;
  }
  .aaa3-list1-heading {
    /* font-size: large; */
    font-weight: bold;
    margin-left: 40px;
  }
  .aaa3-list2-no {
    display: block;
    /* font-size: large; */
  }
  .aaa3-list2-heading {
    display: block;
    /* font-size: large; */
    margin-left: 80px;
  }
  .aaa3-list3-no {
    display: block;
    /* font-size: large; */
    margin-left: 40px;
  }
  .aaa3-list3-heading {
    display: block;
    /* font-size: large; */
    margin-left: 50px;
  }
  .aaa3-checkbox {
    display: flex;
    /* font-size: large; */
  }
  .aaa3-checkbox-label {
    margin-right: 5px;
  }
  .aaa3-checkbox-input {
    cursor: pointer;
    height: 20px !important;
    width: 30px !important;
    margin-top: 3px;
  }
  .app3-appendix-b-ol {
    counter-reset: list;
  }
  
  .app3-appendix-b-ol > li {
    list-style: none;
    position: relative;
  }
  
  .app3-appendix-b-ol > li:before {
    content: "(" counter(list, lower-alpha) ")";
    counter-increment: list;
    /* font-size: large; */
    left: -25px;
    position: absolute;
  }
  .aba3-signature,
  .aca3-signature {
    display: inline-block;
    width: 270px;
    margin-bottom: -5px;
    border-bottom: 1px solid #000;
  }
  .aba3-aoth-attest {
    text-align: center;
    padding: 30px;
    border: 2px solid #45ee5d;
    border-radius: 50%;
  }
  .aca3-aoth-attest {
    display: inline-block;
    text-align: center;
    padding: 30px;
    border: 2px solid #45ee5d;
    border-radius: 50%;
  }
  .aba3-aoth-attest > span,
  .aca3-aoth-attest > span {
    display: block;
  }
  .white-space-pre {
    white-space: pre !important;
  }
  .border-bottom-dotted {
    border: none;
    border-bottom: 2px dotted #000;
    height: 20px !important;
  }
  .app4-appendix-a-list1-ol {
    counter-reset: list;
    margin-left: 50px;
  }
  
  .app4-appendix-a-list1-ol > li {
    list-style: none;
    position: relative;
  }
  
  .app4-appendix-a-list1-ol > li:before {
    content: counter(list, numbers) ".";
    counter-increment: list;
    /* font-size: medium; */
    left: -25px;
    position: absolute;
  }
  .aaa4-member-note {
    display: block;
    /* font-size: medium; */
    margin-left: 65px;
    margin-top: -15px;
    margin-bottom: 30px;
    text-align: justify;
  }
  .app4-appendix-a-list2-ol {
    counter-reset: list;
    margin-left: 30px;
  }
  
  .app4-appendix-a-list2-ol > li {
    list-style: none;
    position: relative;
    /* font-size: large; */
    padding: 5px 0;
    text-align: justify;
  }
  
  .app4-appendix-a-list2-ol > li:before {
    content: counter(list, lower-roman) ".";
    counter-increment: list;
    /* font-size: large; */
    left: -35px;
    position: absolute;
  }
  .app4-appendix-a-list3-ol {
    counter-reset: list;
    margin-left: 60px;
    margin-bottom: 30px;
  }
  
  .app4-appendix-a-list3-ol > li {
    list-style: none;
    position: relative;
    /* font-size: large; */
    padding: 5px 0;
    text-align: justify;
  }
  
  .app4-appendix-a-list3-ol > li:before {
    content: "(" counter(list, lower-alpha) ")";
    counter-increment: list;
    /* font-size: large; */
    left: -50px;
    position: absolute;
  }
  
  .app4-appendix-b-list1-ol {
    counter-reset: list;
    /* font-size: large; */
    padding-left: 25px;
  }
  
  .app4-appendix-b-list1-ol > li {
    list-style: none;
    position: relative;
    text-align: justify;
  }
  
  .app4-appendix-b-list1-ol > li:before {
    content: counter(list, numbers) ".";
    counter-increment: list;
    /* font-size: medium; */
    left: -30px;
    position: absolute;
  }
  
  .app4-appendix-b-list2-ol {
    counter-reset: list;
    /* font-size: large; */
    padding-left: 70px;
  }
  
  .app4-appendix-b-list2-ol > li {
    list-style: none;
    position: relative;
    text-align: justify;
    padding: 10px 0;
  }
  
  .app4-appendix-b-list2-ol > li:before {
    content: "(" counter(list, lower-alpha) ")";
    counter-increment: list;
    /* font-size: large; */
    left: -40px;
    position: absolute;
  }
  .aca4-para {
    /* font-size: large; */
    margin-left: 100px;
  }
  
  .app4-appendix-c-list1-ol {
    counter-reset: list;
    /* font-size: large; */
    padding-left: 130px;
  }
  
  .app4-appendix-c-list1-ol > li {
    list-style: none;
    position: relative;
    text-align: justify;
    padding: 10px 0;
  }
  
  .app4-appendix-c-list1-ol > li:before {
    content: "(" counter(list, lower-alpha) ")";
    counter-increment: list;
    /* font-size: large; */
    left: -50px;
    position: absolute;
  }
  