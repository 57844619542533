.closeIcon-Styling{
  height: 28px !important;
  width: 28px !important;
  margin-right: 30%;
  margin-top: 1px ;
  cursor: pointer;
}
.Button-Styling {
    width: 54px;
    height: 54px;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin-left: 4px;
    margin-right: 0px;
    margin-bottom: 3px;
    border: none;
}
.Button-Styling:hover {
    /* background-color: rgb(46, 45, 45) !important; */
    opacity: 0.7;
}
.calculator {
  position: absolute;
  right: 0%;
  /* right: -155%; */
  top: 54px;
  z-index: 99;
  width: 260px;
  /* background-color: #1e1e2f; */
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* margin-right: 10 auto; */
  /* padding: 10px; */
  /* display: flex;
  flex-direction: column; */
  /* min-height: 46vh;
  max-height: 46vh; */
  height: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.calculator .output1 {
  color: black;
  /* font-size: 18px; */
}

.scrollable1-div {
  overflow-y: scroll;
  height: 200px;
  display: flex;
  flex-direction: column;
}

.display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.output {
  font-size: 1rem;
  color: #333;
  text-align: right;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 0.2fr);
  grid-gap: 0px;
}

.btn1 {
  font-size: 1.5rem;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.btn-secondary {
  background-color: #eee;
  color: #333;
}

.btn-secondary:hover {
  background-color: #ddd;
}

.btn-light1 {
  background-color: #fff !important;
  color: #333;
}

.btn-light:hover {
  background-color: #eee;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0069d9;
}

.equal {
  grid-column: span 2;
}

.zero {
  grid-column: span 2;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #ffffff;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer > a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}
