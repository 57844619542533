.calendar {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    /* overflow-x: auto; */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .col {
    text-align: center;
  }
  
  .days {
    display: flex;
    justify-content: space-between;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
  }
  
  .cell {
    width: 14.28%;
    min-height: 100px;
    max-height: auto;
    display: flex;
    align-items: start;
    justify-content: center;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .cell-dashboard {
    width: 10%;
    min-height: 50px;
    max-height: auto;
    display: flex;
    align-items: start;
    justify-content: center;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .cell.disabled {
    background-color: #f0f0f0;
    color: #bdbdbd;
  }
  
  .cell .number {
    font-size: 20px;
  }
  
  .cell:hover {
    background-color: #e0e0e0;
  }
  
  .isEvent {
    /* background-color: #3174ad; */
    background-color: transparent;
    /* border: 1px solid black; */
    border: none;
    padding: 5px;
    border-radius: 5px;
    /* color: white; */
    color: black;
    margin-top: 2px;
    width: 100%;
  }
  
  .isEventForMultiple {
    background-color: #3174ad;
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin-top: 2px;
    width: 130%;
  }
  
  .events-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
  
    /* position: relative; */
  }
  
  .event {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
    cursor: pointer;
  }
  
  
  
  .show-more-btn {
    margin-top: 5px;
    cursor: pointer;
  }
  
  .dayDesign {
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 5px;
    font-weight: bold;
  }
  
  .today {
    /* background-color: whitesmoke; */
    background-color: #e6f7ff;
  }
  .onSmallHeading1{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  